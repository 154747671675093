import styles from "./index.module.css";
import clsx from "clsx";
import avatar from "../../assets/images/avatar.jpg";
import ChatLoader from "../ChatLoader";
import useChatBot from "../../hooks/useChatBot";
import useChat from "../../hooks/useChat";
import Image from "../Image";
import { useApp } from "../../providers/AppProvider";

type Props = {
  data: IncomingMessage | OutgoingMessage;
  isBot: boolean;
  loading?: boolean;
  handleSend?: (message: string, response?: number) => void;
};

function isIncomingMessage(object: any): object is IncomingMessage {
  return "from" in object;
}

function ChatMessage(props: Props) {
  const { data, isBot, loading, handleSend } = props;

  const message = data.message;
  const messageButtons = data.buttons;
  const { setMessage } = useChatBot();
  const { sendMessage } = useChat();
  const isIncoming = isIncomingMessage(data);
  const { patient } = useApp();

  const className = clsx({
    [styles.root]: true,
    [isIncoming ? styles.incoming : styles.outgoing]: true,
  });

  const boatClasses = clsx({
    [styles.patientRoot]: true,
    [isIncoming ? styles.patientIncoming : styles.patientOutgoing]: true,
  });

  const finalClass = isBot ? boatClasses : className;
  const mainContainerClass = isIncoming
    ? styles.MessageContainerLeft
    : styles.MessageContainerRight;

  const handleSendMessage = async (message: string) => {
    if (handleSend) {
      sendMessage(message);
      handleSend(message);
      setMessage("");
    }
  };

  return (
    <>
      <div className={mainContainerClass}>
        {isBot && (
          <Image
            src={
              (isIncoming
                ? patient?.avatar_128
                : patient?.doctor?.avatar_128) || avatar
            }
            alt={patient?.name}
            className={styles.avatar}
          />
        )}
        <div className={styles.mainMessageClass}>
          {message && (
            <div className={styles.messageList}>
              {
                <div className={finalClass}>
                  <div className={styles.messageContent}>{message}</div>
                </div>
              }
            </div>
          )}
          {loading && (
            <div className={finalClass}>
              <div className={styles.messageContent}>{<ChatLoader />}</div>
            </div>
          )}
        </div>
      </div>
      <div
        className={
          isIncoming
            ? styles.IncomingMessagebuttons
            : styles.OutgoingMessagebuttons
        }
      >
        {messageButtons &&
          messageButtons.map((button: string, index: number) => (
            <button
              key={index}
              className={styles.button}
              onClick={() => handleSendMessage(button)}
              disabled={loading}
            >
              {button}
            </button>
          ))}
      </div>
    </>
  );
}

export default ChatMessage;
