import React from "react";

function getMimeTypeAndAddMetadata(base64String: string) {
  const base64Data = base64String.split(",")[1] || base64String;
  const header = base64Data.slice(0, 4);

  let mimeType = "";
  switch (true) {
    case header.startsWith("/9j/"):
      mimeType = "image/jpeg";
      break;
    case header.startsWith("iVBOR"):
      mimeType = "image/png";
      break;
    case header.startsWith("R0lG"):
      mimeType = "image/gif";
      break;
    case header.startsWith("Qk"):
      mimeType = "image/bmp";
      break;
    case header.startsWith("PD94"):
      mimeType = "image/svg+xml";
      break;
    default:
      mimeType = "unknown";
  }

  // If a valid MIME type is found, return the full Base64 with metadata
  if (mimeType !== "unknown") {
    return `data:${mimeType};base64,${base64Data}`;
  } else {
    return base64Data; // Invalid image type
  }
}
function Image(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  const image = getMimeTypeAndAddMetadata(props.src!);
  const { src, alt = "", ...rest } = props;
  console.log(src);

  return <img src={image} alt={alt} {...rest} />;
}

export default Image;
