import { useEffect } from "react";
import Box from "../../components/Box";
import { useApi } from "../../hooks/useApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApp } from "../../providers/AppProvider";

type AuthPayload = {
  employee_id?: number;
  customer_id?: number;
  event_id: number;
};

function Authorization() {
  const api = useApi();
  const [searchParams] = useSearchParams();
  const {
    setTokens,
    setEventId,
    employeeId,
    customerId,
    eventId,
    setEmployee,
    setPatient,
    token,
  } = useApp();

  const navigate = useNavigate();
  const event_id = searchParams.get("event_id") || eventId?.toString();

  const authUser = async (params: AuthPayload) => {
    try {
      Object.keys(params).forEach((item) => {
        if (typeof params[item as keyof AuthPayload] === "undefined") {
          delete params[item as keyof AuthPayload];
        }
      });

      const { data } = await api.post<{
        token: string;
        jitsi_jwt: string;
        room_name: string;
      }>("/authenticate", params);

      setEventId(params.event_id);
      setTokens({
        token: data.token,
        jitsi_token: data.jitsi_jwt,
      });
      localStorage.setItem("token", data.token);
      if (params.employee_id && token) {
        const { data } = await api.get(`/employees/${params.employee_id}`);
        setEmployee(data);
      }
      if (params.customer_id && token) {
        const { data: patient } = await api.get(
          `/partners/${params.customer_id}`
        );
        api
          .get(`/partners/${params.customer_id}/doctor`)
          .then(({ data }) => {
            setPatient({ ...patient, doctor: data });
          })
          .catch(console.log);
        setPatient(patient);
      }

      let redirectTo = params?.employee_id ? "dashboard" : "patient-videocall";

      navigate(`/${redirectTo}/${data.room_name}`, {
        state: {
          jwt: data.jitsi_jwt,
          event_id,
          employee_id: employeeId,
          customer_id: customerId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (event_id != null && (employeeId != null || customerId != null)) {
      authUser({
        event_id: parseInt(event_id),
        employee_id: employeeId,
        customer_id: customerId,
      });
    } else {
      navigate("/");
    }
  }, [event_id, employeeId, customerId]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      height="100vh"
      flexDirection="column"
      textAlign="center"
    >
      <h5>Please wait a moment we'll navigate to meeting shortly</h5>
      <div
        style={{
          width: "100%",
          height: "50vh",
          zIndex: 999999,
          overflow: "visible",
          background: "#fff url(/loader.gif) no-repeat center center",
        }}
      ></div>
    </Box>
  );
}

export default Authorization;
